
export const projectList = `
  query ProjectList {
    projects(order: "name", where: { bespoke: false }) {
      name,
      icon: imageUrl,
      id,
      description,
      allowContributors
    }
  }
`;

export const organisationList = `
  query OrganisationList {
    organisations {
      id
      name
    }
  }
`;

export const organisationDetail = `
  query OrganistionDetail($id: Int!) {
    organisation(id: $id) {
      id,
      name,
      folders{
        id
        name
        parentId
      }
      projects{
          id
          name
          icon: imageUrl
          description
          organisationFolderId
      }
    }
  }
`;

export const projectSummary = `
  query ProjectSummary($projectId: Int!) {
    project: projectOverview(id: $projectId) {
      name
      icon: imageUrl
      description
      allowContributors
    }
  }
`;

export const joinProjectMutation = `
  mutation JoinProject($projectId: Int!){
    joinProject(input:{ projectId: $projectId } ){
      role
    }
  }
`;

export const collectionItemBoundingBox = `
  query CollectionItemBoundingBox($projectId: Int!, $collectionId: Int!, $itemId: Int!){
    project(id: $projectId) {
      collections(where: { id: $collectionId }) {
        items(where: { id: $itemId }) {
          boundingBox {
            type,
            coordinates
          }
        }
      }
    }
  }
`;

export const recordFieldsFragment = `
  fragment recordFields on Record{
    id
    data
    state
    geometry {
      type,
      coordinates
    }
    userId
    surveyId
    updatedAt: updatedAtPrecise
    createdAt
    deleted
    user {
      username
    }
  }
`;

export const presignedUrl = `
  query PresignedUrl($projectId: Int!, $fileName: String!, $contentType: String!) {
    url: presignedURL(projectId: $projectId, fileName: $fileName, contentType: $contentType)
  }
`;
