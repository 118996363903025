import { createSelector } from 'reselect';

import FilteringService from '../services/filtering.service';
import { CoreoApp, CoreoAttribute, CoreoCollection, CoreoCollectionItem, CoreoForm, CoreoMapLayer, CoreoProjectFeatures, CoreoRecord, CoreoRecordFilter, CoreoUser } from '../types';
import { LayerInitialConfig } from '../types/forms.types';

import * as appSelectors from './app/app.selectors';
import * as authSelectors from './auth/auth.selectors';
import { evaluateConditions } from './forms/forms.reducer';
import * as formsSelectors from './forms/forms.selectors';
import * as formsSupportSelectors from './formsSupport/formsSupport.selectors';
import { MapInstanceState, MapLayerState, MapState } from './map/map.reducer';
import * as mapSelectors from './map/map.selectors';
import { OfflineMapsState } from './offlineMaps/offlineMaps.reducer';
import * as offlineMapsSelectors from './offlineMaps/offlineMaps.selectors';
import * as recordsSelectors from './records/records.selectors';
import { ApplicationState } from './reducers';
import { MapDataLayer } from '../services/maps.service';
import { Feature, Geometry } from 'geojson';

export const getAuthState = (state: ApplicationState) => state.auth;
export const getAuthError = createSelector(getAuthState, authSelectors.getAuthError);
export const getAuthInProgress = createSelector(getAuthState, authSelectors.getAuthInProgress);
export const getAuthIsAuth = createSelector(getAuthState, authSelectors.getAuthIsAuth);
export const getAuthToken = createSelector(getAuthState, authSelectors.getAuthToken);
export const getAuthUser = createSelector(getAuthState, authSelectors.getAuthUser);
export const getAuthUserImageUrl = createSelector(getAuthState, authSelectors.getAuthUserImageUrl);
export const getAuthUserId = createSelector(getAuthState, authSelectors.getAuthUserId);

export const getAppState = (state: ApplicationState) => state.app;
export const getAppReady = createSelector(getAppState, appSelectors.getAppReady);
export const getAppSettings = createSelector(getAppState, appSelectors.getAppSettings);
export const getAppNetworkConnectionType = createSelector(getAppState, appSelectors.getAppNetworkConnectionType);
export const getAppNetworkConnected = createSelector(getAppState, appSelectors.getAppNetworkConnected);
export const getAppIsInstalled = id => createSelector(getAppState, appSelectors.getAppIsInstalled(id));
export const getAppStatus = id => createSelector(getAppState, appSelectors.getAppStatus(id));
export const getApps = createSelector(getAppState, appSelectors.getApps);
export const getAvailableApps = createSelector(getAppState, appSelectors.getAvailableApps);
export const getAppsCount = createSelector(getAppState, appSelectors.getAppsCount);
export const getCurrentAppStatus = createSelector(getAppState, appSelectors.getCurrentAppStatus);
export const getCurrentAppWelcomeLastSeen = createSelector(getAppState, appSelectors.getCurrentAppWelcomeLastSeen);
export const getCurrentAppLastSync = createSelector(getAppState, appSelectors.getCurrentAppLastSync);
export const getCurrentAppSyncState = createSelector(getAppState, appSelectors.getCurrentAppSyncState);
export const getAppMediaDownloadState = id => createSelector(getAppState, appSelectors.getAppMediaDownloadState(id));
export const getAppAttachmentDownloadState = id => createSelector(getAppState, appSelectors.getAppAttachmentDownloadState(id));
export const getAppOfflineMigrationComplete = createSelector(getAppState, appSelectors.getAppOfflineMigrationComplete);
export const getAppAttachmentsShouldDownload = createSelector(getAppState, appSelectors.getAppAttachmentsShouldDownload);
export const getAppAttachmentsShouldDownloadForApp = id => createSelector(getAppState, appSelectors.getAppAttachmentsShouldDownloadForApp(id));

export const getApp = createSelector(getAppState, appSelectors.getApp);
export const getAppId = createSelector(getAppState, appSelectors.getAppId);
export const getAppName = createSelector(getAppState, appSelectors.getAppName);
export const getAppProjectId = createSelector(getAppState, appSelectors.getAppProjectId);
export const getAppForms = createSelector(getAppState, appSelectors.getAppForms);
export const getAppFormsWithGeometry = createSelector(getAppState, appSelectors.getAppFormsWithGeometry);
export const getAppMediaTags = createSelector(getAppState, appSelectors.getAppMediaTags);
export const getAppMediaItems = createSelector(getAppState, appSelectors.getAppMediaItems);
export const getAppCollections = createSelector(getAppState, appSelectors.getAppCollections);
export const getAppGeometricCollections = createSelector(getAppState, appSelectors.getAppGeometricCollections);
export const getAppGeometricCollectionsForMap = createSelector(getAppState, appSelectors.getAppGeometricCollectionsForMap);
export const getAppCollection = id => createSelector(getAppState, appSelectors.getAppCollection(id));
export const getAppFeatures = createSelector(getAppState, appSelectors.getAppFeatures);
export const getAppFeatureIsEnabled = (f: keyof CoreoProjectFeatures) => createSelector(getAppState, appSelectors.getAppFeatureIsEnabled(f));
export const getAppAttributes = createSelector(getAppState, appSelectors.getAppAttributes);
export const getAppAttributesForFilter = createSelector(getAppState, appSelectors.getAppAttributesForFilter);
export const getAppPages = createSelector(getAppState, appSelectors.getAppPages);
export const getAppPagesForMenu = createSelector(getAppState, appSelectors.getAppPagesForMenu);
export const getAppTitleForRecord = (record: CoreoRecord) => createSelector(getAppState, appSelectors.getAppTitleForRecord(record));
export const getAppDescriptionForRecord = (record: CoreoRecord, label = false) => createSelector(getAppState, appSelectors.getAppDescriptionForRecord(record, label));
export const getAppDescriptionAttributes = createSelector(getAppState, appSelectors.getAppDescriptionAttributes);
export const getAppPage = id => createSelector(getAppState, appSelectors.getAppPage(id));
export const getAppStates = createSelector(getAppState, appSelectors.getAppStates);
export const getAppDefaultState = createSelector(getAppState, appSelectors.getAppDefaultState);
export const getAppForm = id => createSelector(getAppState, appSelectors.getAppForm(id));
export const getAppAttributesForForm = id => createSelector(getAppState, appSelectors.getAppAttributesForForm(id));
export const getAppAssociationAttributesForForm = id => createSelector(getAppState, appSelectors.getAppAssociationAttributesForForm(id));
export const getAppAttributesForDisplay = id => createSelector(getAppState, appSelectors.getAppAttributesForDisplay(id));
export const getAppTitleAttributeForForm = id => createSelector(getAppState, appSelectors.getAppTitleAttributeForForm(id));
export const getAppDescriptionAttributeForForm = id => createSelector(getAppState, appSelectors.getAppDescriptionAttributeForForm(id));
export const getAppAttributesForCollection = id => createSelector(getAppState, appSelectors.getAppAttributesForCollection(id));
// export const getAppLastUpdatedStatus = id => createSelector(getAppState, appSelectors.getAppLastUpdatedStatus(id));
export const getAppLastSync = id => createSelector(getAppState, appSelectors.getAppLastSync(id));
// export const getAppLastCheckedForUpdate = id => createSelector(getAppState, appSelectors.getAppLastCheckedForUpdate(id));
// export const getAppMaps = createSelector(getAppState, appSelectors.getAppMaps);
export const getAppMapLayers = createSelector(getAppState, appSelectors.getAppMapLayers);
export const getAppMapLayer = id => createSelector(getAppState, appSelectors.getAppMapLayer(id));
export const getAppBounds = createSelector(getAppState, appSelectors.getAppBounds);
export const getAppBoundsBoundingBox = createSelector(getAppState, appSelectors.getAppBoundsBoundingBox);

// export const getAppMap = id => createSelector(getAppState, appSelectors.getAppMap(id));
export const getAppEnvironment = createSelector(getAppState, appSelectors.getAppEnvironment);
export const getAppConfig = createSelector(getAppState, appSelectors.getAppConfig);
export const getAppConfigAdminUrl = createSelector(getAppState, appSelectors.getAppConfigAdminUrl);
export const getAppConfigMapboxToken = createSelector(getAppState, appSelectors.getAppConfigMapboxToken);
export const getAppConfigMapsUrl = createSelector(getAppState, appSelectors.getAppConfigMapsUrl);
export const getAppConfigApiUrl = createSelector(getAppState, appSelectors.getAppConfigApiUrl);
export const getAppConfigSentryDsn = createSelector(getAppState, appSelectors.getAppConfigSentryDsn);
export const getAppConfigEnvironmentName = createSelector(getAppState, appSelectors.getAppConfigEnvironmentName);
export const getAppConfigBingToken = createSelector(getAppState, appSelectors.getAppConfigBingToken);
export const getAppLocalDatabaseName = createSelector(getAppState, appSelectors.getLocalDatabaseName);
export const getAppIsDev = createSelector(getAppState, appSelectors.getAppIsDev);

export const getAppIcon = createSelector(getAppState, appSelectors.getAppIcon);
export const getAppTutorialShown = createSelector(getAppState, appSelectors.getAppTutorialShown);
export const getAppWelcomePageId = createSelector(getAppState, appSelectors.getAppWelcomePageId);
// export const getAppFreeTrialExpired = createSelector(getAppState, appSelectors.getAppFreeTrialExpired);
export const getAppHasGeometry = createSelector(getAppState, appSelectors.getAppHasGeometry);

export const getFormsState = (state: ApplicationState) => state.forms;
export const getFormsForms = createSelector(getFormsState, formsSelectors.getFormsForms);
export const getFormsCurrentForm = createSelector(getFormsState, formsSelectors.getFormsCurrentForm);
export const getFormsCurrentFormId = createSelector(getFormsState, formsSelectors.getFormsCurrentFormId);
export const getFormsForm = uuid => createSelector(getFormsState, formsSelectors.getFormsForm(uuid));
export const getFormsFormSections = uuid => createSelector(getFormsState, formsSelectors.getFormsFormSections(uuid));
export const getFormsFormBlocks = uuid => createSelector(getFormsState, formsSelectors.getFormsFormBlocks(uuid));
export const getFormsFormRecord = uuid => createSelector(getFormsState, formsSelectors.getFormsFormRecord(uuid));
export const getFormsFormDirty = uuid => createSelector(getFormsState, formsSelectors.getFormsFormDirty(uuid));
export const getFormsFormReady = uuid => createSelector(getFormsState, formsSelectors.getFormsFormReady(uuid));
export const getFormsFormSectionId = uuid => createSelector(getFormsState, formsSelectors.getFormsFormSectionId(uuid));
export const getFormsFormNextSectionId = uuid => createSelector(getFormsState, formsSelectors.getFormsFormNextSectionId(uuid));
export const getFormsFormPrevSectionId = uuid => createSelector(getFormsState, formsSelectors.getFormsFormPrevSectionId(uuid));

export const getFormsSupportState = (state: ApplicationState) => state.formsSupport;
export const getFormsSupportCache = createSelector(getFormsSupportState, formsSupportSelectors.getFormsSupportCache);
export const getFormsSupportFormCache = formId => createSelector(getFormsSupportState, formsSupportSelectors.getFormsSupportFormCache(formId));
export const getFormsSupportGeometrySettings = createSelector(getFormsSupportState, formsSupportSelectors.getFormsSupportGeometrySettings);
export const getFormsSupportAttributeState = <T = any>(attributeId: string) => createSelector(getFormsSupportState, formsSupportSelectors.getFormsSupportAttributeState<T>(attributeId));

export const getRecordsState = (state: ApplicationState) => state.records;
export const getRecordsFilter = createSelector(getRecordsState, recordsSelectors.getRecordFilter);
export const getRecordsLoading = createSelector(getRecordsState, recordsSelectors.getRecordsLoading);
export const getRecordsMapLoading = createSelector(getRecordsState, recordsSelectors.getRecordsMapLoading);
export const getRecordsRecord = createSelector(getRecordsState, recordsSelectors.getRecordsRecord);

export const getMapState = (state: ApplicationState): MapState => state.map;
export const getMapBaseStyleId = createSelector(getMapState, mapSelectors.getMapBaseStyleId);
export const getMapShowAppBounds = createSelector(getMapState, mapSelectors.getMapShowAppBounds);
export const getMapShowScaleBar = createSelector(getMapState, mapSelectors.getMapShowScaleBar);

export const getOfflineMapsState = (state: ApplicationState): OfflineMapsState => state.offlineMaps;
export const getOfflineMapsMaps = createSelector(getOfflineMapsState, offlineMapsSelectors.getOfflineMapsMaps);
export const getOfflineMapsAmbientEnabled = createSelector(getOfflineMapsState, offlineMapsSelectors.getOfflineMapsAmbientEnabled);
export const getOfflineMapsMap = (uuid: string) => createSelector(getOfflineMapsState, offlineMapsSelectors.getOfflineMapsMap(uuid));

const createFormFilter = (publicFormIds, privateFormIds, userId) => {
  if (privateFormIds.length === 0) {
    if (publicFormIds.length === 0) {
      return {};
    }
    return {
      surveyId: {
        in: publicFormIds
      }
    };
  }

  const privateFormsFilter = {
    surveyId: {
      in: privateFormIds,
    },
    userId
  }

  if (publicFormIds.length === 0) {
    return privateFormsFilter;
  }

  return {
    or: [
      privateFormsFilter,
      {
        surveyId: {
          in: publicFormIds.filter(id => !privateFormIds.includes(id))
        }
      }]
  }
}

export const getRecordsBaseFilter = createSelector(getAppForms, getAuthUserId, (appForms: CoreoForm[], userId: number) => {
  const formIds = appForms.map(f => f.id);
  const selectedPrivateFormIds = appForms.filter(f => f.private && formIds.includes(f.id)).map(f => f.id);
  const selectedPublicFormIds = formIds.filter(id => !selectedPrivateFormIds.includes(id));
  return createFormFilter(selectedPublicFormIds, selectedPrivateFormIds, userId);
});

export const getRecordsCoreoFilter = createSelector(getRecordsFilter, getAppForms, getAuthUserId, (filter: CoreoRecordFilter, appForms: CoreoForm[], authUserId: number) => {
  const { customFilters, customOperator, states, forms, userId, to, from } = filter;
  const result: any = {};

  const selectedFormIds = forms.length ? forms : appForms.map(f => f.id);

  const selectedPrivateFormIds = appForms.filter(f => f.private && selectedFormIds.includes(f.id)).map(f => f.id);
  const selectedPublicFormIds = selectedFormIds.filter(id => !selectedPrivateFormIds.includes(id));

  if (states && states.length > 0) {
    result.state = {
      in: filter.states
    };
  }

  result['and'] = result['and'] || [];

  result['and'].push(createFormFilter(selectedPublicFormIds, selectedPrivateFormIds, authUserId));

  // if (forms && forms.length > 0) {
  //   result.surveyId = {
  //     in: filter.forms
  //   };
  // }

  if (userId) {
    result.userId = userId;
  }

  // From and To
  if (to && from) {
    result.createdAt = {
      between: [new Date(from).toISOString(), new Date(to).toISOString()]
    };
  } else if (from) {
    result.createdAt = {
      gte: new Date(from).toISOString()
    };
  } else if (to) {
    result.createdAt = {
      lte: new Date(to).toISOString()
    };
  }

  // If not custom operators, return
  if (customFilters.length === 0) {
    return result;
  }

  const customSelectors = [];
  for (const custom of customFilters.filter(c => FilteringService.instance.customFilterIsValid(c))) {
    const { operator, value, attribute: { path, type }, formId } = custom;
    let att = {};
    let parsedValue: any = value;
    if (Array.isArray(value)) {
      parsedValue = [...value];
    }
    if (type === 'multiselect' && operator.multiple) {
      const selectors = [];
      for (const val of parsedValue) {
        selectors.push({
          data: {
            [path]: {
              like: '%"' + val + '"%'
            }
          }
        });
      }
      const [op1, op2] = operator.id.split(':'); //
      if (typeof op2 !== 'undefined') {
        customSelectors.push({
          [op1]: { // if op2 is present, op1 is 'not'
            [op2]: selectors
          }
        })
      } else {
        customSelectors.push({
          [op1]: selectors
        })
      }
    }
    else {
      if (operator.id.indexOf(':') !== -1) {
        const [op, v] = operator.id.split(':');
        parsedValue = v === 'true' ? true : v === 'false' ? false : (v === 'null' ? null : v);
        att = { [op]: parsedValue }
      } else {
        att = { [operator.id]: value }
      }
      customSelectors.push({
        surveyId: formId,
        data: {
          [path]: att
        }
      });
    }
  }
  if (customSelectors.length) {
    if (customOperator === 'not') {
      result['and'] = customSelectors.map(c => ({ not: c }));
    } else {
      result['' + customOperator] = customSelectors;
    }
  }
  return result;
});

export const getShouldShowTutorial = createSelector(getAuthIsAuth, getAppTutorialShown, (isAuth, tutorialShown) => isAuth && !tutorialShown);

// export const getPendingRecordsForActiveApp = createSelector(
//   getAppId,
//   getRecordsPending,
//   (projectId, pending) => pending.filter(p => p.projectId === projectId)
// );

/**
 * Returns the records to display of the currently loaded record,
 * evaluating any conditionals present in the attributes to ensure
 * only displaying the attributes that are relevant to the record
 */
export const getRecordsRecordAttributes = createSelector(
  getRecordsRecord,
  getAppAttributes,
  (record, attributes) =>
    appSelectors.filterAttributesForForm(record.surveyId, attributes)
      .filter((attribute: CoreoAttribute, _i: number, formAttributes: CoreoAttribute[]) => evaluateConditions(attribute.conditions, formAttributes, record)
      ));

export const getRecordAttributes = (record: CoreoRecord) => createSelector(getAppAttributes,
  (attributes: CoreoAttribute[]) =>
    appSelectors.filterAttributesForForm(record.surveyId, attributes)
      .filter((attribute: CoreoAttribute, _i: number, formAttributes: CoreoAttribute[]) => evaluateConditions(attribute.conditions, formAttributes, record)
      ));

export const getRecordsRecordCanBeEdited = (record: CoreoRecord) => createSelector(
  getAuthUser,
  getApp,
  getAppForms,
  (user: CoreoUser, app: CoreoApp, forms: CoreoForm[]) => {
    const form = forms.find(f => f.id === record?.surveyId);

    return (user.role === 'admin') ||
      (record?.userId === user.userId) ||
      (app.membership === 'admin' || app.membership === 'moderator') ||
      (form?.allowMemberUpdate);

  });

export const sortCollectionItems = (items: CoreoCollectionItem[], collection: CoreoCollection) => createSelector(getAppAttributesForCollection(collection.id), (attributes) => {
  switch (collection.sortMode) {
    case 'alphabetical': {
      items.sort((a, b) => {
        const aValue = String(a.value).toLowerCase();
        const bValue = String(b.value).toLowerCase();
        if (aValue < bValue) {
          return -1;
        }
        else if (aValue > bValue) {
          return 1;
        } else {
          return 0;
        }
      });
      break;
    }
    case 'attribute': {
      const sortAttribute = attributes.find(a => a.id === collection.sortAttributeId);
      // Fallback to sort order
      if (!sortAttribute) {
        items.sort((a, b) => a.sort - b.sort);
        break;
      }

      const numericComparison = sortAttribute.type === 'float' || sortAttribute.type === 'integer';

      items.sort((a, b) => {
        const aData = a.data ? a.data[sortAttribute.path] : null;
        const bData = b.data ? b.data[sortAttribute.path] : null;

        const aValue = numericComparison ? parseFloat(aData) : String(aData).toLowerCase();
        const bValue = numericComparison ? parseFloat(bData) : String(bData).toLowerCase();
        if (aValue < bValue) {
          return -1;
        }
        else if (aValue > bValue) {
          return 1;
        } else {
          return 0;
        }
      });
      break;
    }
    default: {
      items.sort((a, b) => a.sort - b.sort);
      break;
    }
  }
  return items;
});

const formToMapLayerState = (form: CoreoForm, state: Partial<MapLayerState> = {}): MapLayerState => ({
  id: `records_${form.id}`,
  layerType: 'records',
  sourceId: form.id,
  enabled: form.mapVisible,
  locked: false,
  ...state
});

const collectionToMapLayerState = (collection: CoreoCollection, state: Partial<MapLayerState> = {}): MapLayerState => ({
  id: `collection_${collection.id}`,
  layerType: 'collection',
  sourceId: collection.id,
  enabled: collection.mapVisible,
  ...state,
});

const mapLayerToMapLayerState = (layer: CoreoMapLayer, state: Partial<MapLayerState> = {}): MapLayerState => ({
  id: `custom_${layer.id}`,
  layerType: 'custom',
  sourceId: layer.id,
  enabled: layer.visible,
  ...state
});

export const applyInitialMapLayerConfig = (state: MapInstanceState, config: LayerInitialConfig[] = []) => {
  for (const layer of [...state.dataLayers, ...state.layers]) {
    const match = config.find(c => c.type === layer.layerType && c.id === layer.sourceId);
    layer.enabled = typeof match !== 'undefined' || layer.enabled;
  }
}

export const getProjectMapBaseInstanceState = (id: string, state: ApplicationState) => {
  const forms = getAppFormsWithGeometry(state);
  const collections = getAppGeometricCollectionsForMap(state);
  const mapLayers = getAppMapLayers(state);
  const mapState = getMapState(state);
  const projectId = getAppId(state);
  const bounds = getAppBounds(state);
  // Building New State
  let dataLayers: MapLayerState[] = [];
  const layers: MapLayerState[] = [];

  for (const form of [...forms].sort((a, b) => a.mapSort - b.mapSort)) {
    // const initialConfig = config.find(f => f.type == 'records' && f.id === form.id);
    // const enabled = typeof initialConfig !== 'undefined' || form.mapVisible;
    dataLayers.push(formToMapLayerState(form));
  }

  const orderMap: Map<string, number> = new Map<string, number>();

  for (const collection of collections) {
    // const initialConfig = config.find(f => f.type == 'collection' && f.id === collection.id);
    // const enabled = typeof initialConfig !== 'undefined' || collection.mapVisible;
    const collectionLayer = collectionToMapLayerState(collection);
    layers.push(collectionLayer);
    orderMap.set(collectionLayer.id, collection.mapSort);
  }

  for (const layer of mapLayers) {
    // const initialConfig = config.find(f => f.type == 'custom' && f.id === layer.id);
    // const enabled = typeof initialConfig !== 'undefined' || layer.visible;
    const mapLayerLayer = mapLayerToMapLayerState(layer);
    layers.push(mapLayerLayer);
    orderMap.set(mapLayerLayer.id, layer.sort);
  }

  layers.sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id));

  const output: MapInstanceState = {
    id,
    baseStyleId: mapState.baseStyleId,
    showAppBounds: !!bounds && mapState.showAppBounds,
    showScaleBar: mapState.showScaleBar,
    layers,
    dataLayers,
    projectId
  };
  return output;
};

export const getProjectMapInstanceState = (id: string) => createSelector(getMapState, (mapState) => {
  if (!(id in mapState.maps)) {
    return null;
  }
  const output: MapInstanceState = {
    id,
    baseStyleId: mapState.baseStyleId,
    showAppBounds: mapState.showAppBounds,
    showScaleBar: mapState.showScaleBar,
    ...mapState.maps[id]
  };
  return output;
});

export const consolidateMapState = (state: MapInstanceState) => createSelector(getAppFormsWithGeometry, getAppGeometricCollectionsForMap, getAppMapLayers, (forms, collections, mapLayers) => {
  const projectFormIds = new Set([...forms.map(form => form.id)]);

  // Remove any non existent data layers
  const dataLayers = state.dataLayers.filter(layer => projectFormIds.has(layer.sourceId));

  // Remove any non existent layers
  const layers = state.layers.filter(layer => {
    if (layer.layerType === 'collection') {
      return typeof collections.find(collection => collection.id === layer.sourceId) !== 'undefined';
    }
    if (layer.layerType === 'custom') {
      return typeof mapLayers.find(mapLayer => mapLayer.id === layer.sourceId) !== 'undefined';
    }
    // Don't know what this is?
    return false;
  });

  // Find missing layers
  const newDataLayers = forms.filter(form => dataLayers.findIndex(d => d.sourceId === form.id) === -1).map(form => formToMapLayerState(form));
  const newLayers: MapLayerState[] = [];

  for (const collection of collections) {
    if (typeof layers.find(l => l.layerType === 'collection' && l.sourceId === collection.id) === 'undefined') {
      const newCollectionLayer = collectionToMapLayerState(collection);
      newCollectionLayer.enabled = false;
      newLayers.push(newCollectionLayer);
    }
  }
  for (const mapLayer of mapLayers) {
    if (typeof layers.find(l => l.layerType === 'custom' && l.sourceId === mapLayer.id) === 'undefined') {
      const newMapLayerLayer = mapLayerToMapLayerState(mapLayer);
      newMapLayerLayer.enabled = false;
      newLayers.push(newMapLayerLayer);
    }
  }

  const removed = (state.dataLayers.length - dataLayers.length) + (state.layers.length - layers.length);

  const updatedState = {
    ...state,
    dataLayers: [...dataLayers, ...newDataLayers],
    layers: [...layers, ...newLayers]
  };

  return {
    state: updatedState,
    newLayers,
    newDataLayers,
    removed
  };

});

export const getFilterableAttributes = createSelector(getAppAttributesForFilter, getRecordsFilter, (attributes, filter) => {
  if (filter.forms.length !== 1) {
    return [];
  }

  // return attributes.filter(a => a.filterable && a.surveyId === filter.forms[0]);
  return [...attributes.filter(a => a.surveyId === filter.forms[0])].sort((a, b) => a.order - b.order);
});

export interface Coreov1PendingRecord {
  data: any;
  geometry: any;
  id: number;
  projectId: number;
  surveyId: number;
  updatedAt: string;
  pendingState: number;
  formId: number;
  attachments: any[];
};


export const getV1PendingRecords = (state: ApplicationState) => {
  return (state.records as any)?.pending ?? [] as Coreov1PendingRecord[];
}

export const getV1PendingRecordsCount = createSelector(getV1PendingRecords, p => p.length);

export const getFormsFormSupportingRecords = createSelector(getAppForms, getFormsState, (appForms, forms) => {
  const layer = new MapDataLayer([]);
  const features: Feature<Geometry>[] = [];

  const activeRecord = forms[forms.forms.length - 1]?.record;

  for (const appForm of appForms) {
    layer.add(appForm.id, appForm.mapSort, appForm.style);
  }

  const processSupportingRecord = (record: CoreoRecord) => {
    if ((record !== activeRecord) && record.geometry) {
      features.push({
        type: 'Feature',
        properties: {
          surveyId: record.surveyId,
          data: record.data
        },
        geometry: record.geometry
      });
    }
    for (const k in record.associates) {
      for (const r in record.associates[k]) {
        processSupportingRecord(record.associates[k][r]);
      }
    }
  }

  for (const form of forms.forms) {
    processSupportingRecord(form.record)
  }

  layer.setFeatures(features);
  return layer;
});
