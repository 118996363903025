import { capSQLiteSet } from "@capacitor-community/sqlite";
import SQLDatabase, {
  SQLColumn,
  SQLQueryBuilder,
  SQLSelect,
} from "../sql.service";

export type AppDatabaseTable =
  | "projects"
  | "collections"
  | "forms"
  | "states"
  | "pages"
  | "attributes"
  | "records"
  | "users"
  | "attachments"
  | "associations"
  | "items"
  | "mediaItems";

type AppDatabaseRepoQuery = number | SQLSelect | SQLQueryBuilder;

export type CoreoAsyncIterable<T> = AsyncIterable<T[]> & {
  toArray: () => Promise<T[]>;
  offset: number;
  done: boolean;
  next: () => Promise<IteratorResult<T[]>>;
};

export abstract class AppDatabaseRepo<D, S, A> {
  abstract columns: string[][];
  abstract references: string[];
  abstract indexes: string[];
  abstract tableName: string;
  abstract primaryKey: string[];

  order: string;

  public abstract toInsertSet(entity: S): capSQLiteSet[];

  public async findById(id: number): Promise<A> {
    const query = SQLDatabase.select().from(this.tableName).where("id = ?", id);

    const result = await SQLDatabase.instance.query<D>(query);

    if (result.length === 0) {
      return null;
    }

    const serialised = result[0];
    return this.deserialise(serialised);
  }

  private buildQuery(query: AppDatabaseRepoQuery): SQLSelect {
    if (typeof query === "number") {
      return SQLDatabase.select().from(this.tableName).where("id = ?", query);
    }
    if (typeof query === "function") {
      return query(SQLDatabase.select().from(this.tableName));
    }
    return query;
  }

  public async find(query: AppDatabaseRepoQuery): Promise<A[]> {
    const result = await SQLDatabase.instance.query<D>(this.buildQuery(query));
    return Promise.all(result.map((r) => this.deserialise(r)));
  }

  public async findOne(query: AppDatabaseRepoQuery): Promise<A> {
    const q = this.buildQuery(query);
    q.limit(1);
    const result = await SQLDatabase.instance.query<D>(q);
    if (result.length === 0) {
      console.warn("findOne failed", q.toString());
      return null;
    }
    return this.deserialise(result[0]);
  }

  public async query(query: Partial<D>): Promise<A[]> {
    const q = SQLDatabase.select().from(this.tableName);
    for (const p in query) {
      q.where(`${p} = ? `, query[p]);
    }
    if (this.order) {
      q.order(this.order);
    }
    const { text, values } = q.toParam();
    const result = await SQLDatabase.instance.query<D>(text, values);
    return Promise.all(result.map((r) => this.deserialise(r)));
  }

  public async queryOne(query: Partial<D>): Promise<A> {
    const q = SQLDatabase.select().from(this.tableName);
    for (const p in query) {
      q.where(`${p} = ? `, query[p]);
    }
    if (this.order) {
      q.order(this.order);
    }
    q.limit(1);

    const { text, values } = q.toParam();
    const result = await SQLDatabase.instance.query<D>(text, values);

    if (result.length === 0) {
      console.warn("queryOne failed", text, values);
      return null;
    }

    return this.deserialise(result[0]);
  }

  async count(query: AppDatabaseRepoQuery): Promise<number> {
    const q = this.buildQuery(query);
    q.field("COUNT(*)", "count");
    return SQLDatabase.instance.query<{ count: number }>(q).then((result) => {
      return result[0].count;
    });
  }

  public async initTable(): Promise<capSQLiteSet[]> {
    const existingColumns = await SQLDatabase.instance.tableColumns(
      this.tableName
    );
    if (existingColumns.length === 0) {
      return this.createTable();
    } else {
      return this.migrate(existingColumns);
    }
  }

  private createTableStatement(tableName: string = this.tableName): capSQLiteSet {
    const statements: string[] = [];
    for (const [name, type] of this.columns) {
      statements.push(`"${name}" ${type}`);
    }
    for (const reference of this.references) {
      statements.push(reference);
    }
    if (this.primaryKey.length > 0) {
      const pk = `PRIMARY KEY (${this.primaryKey.map(p => `"${p}"`).join(",")})`;
      statements.push(pk);
    }
    return {
      statement: `CREATE TABLE IF NOT EXISTS ${tableName} (
        ${statements.join(",")})`,
      values: [],
    };
  }

  private createTableIndexStatement(): capSQLiteSet[] {
    return this.indexes.map((index) => ({
      statement: index,
      values: [],
    }));
  }

  public createTable(): capSQLiteSet[] {
    const operations: capSQLiteSet[] = [];
    operations.push(this.createTableStatement());
    operations.push(...this.createTableIndexStatement());
    return operations;
  }

  public async migrate(columns: SQLColumn[]): Promise<capSQLiteSet[]> {
    const operations: capSQLiteSet[] = [];

    const currentPK = columns.filter(c => c.pk).map(c => c.name);

    if (currentPK.length !== this.primaryKey.length) {
      console.log('PK Migration!');
      operations.push({ statement: 'BEGIN TRANSACTION', values: [] });
      operations.push(this.createTableStatement('temp_' + this.tableName));
      operations.push({
        statement: `INSERT INTO temp_${this.tableName} SELECT * FROM ${this.tableName}`,
        values: [],
      });
      operations.push({
        statement: `DROP TABLE ${this.tableName}`,
        values: [],
      });
      operations.push({
        statement: 'ALTER TABLE temp_' + this.tableName + ' RENAME TO ' + this.tableName,
        values: []
      });
      operations.push(...this.createTableIndexStatement());
      operations.push({ statement: 'COMMIT TRANSACTION', values: [] });
      console.log('Run', operations.slice(0));
    }

    for (const [name, type] of this.columns) {
      const exists = columns.findIndex((c) => c.name === name) !== -1;
      if (!exists) {
        operations.push({
          statement: `ALTER TABLE "${this.tableName}" ADD COLUMN "${name}" ${type}`,
          values: [],
        });
      }
    }
    for (const index of this.indexes) {
      operations.push({
        statement: index,
        values: [],
      });
    }
    return operations;
  }

  public toDeleteSet(id: number): capSQLiteSet[] {
    return [
      {
        statement: `DELETE FROM ${this.tableName} WHERE projectId = ?`,
        values: [id],
      },
    ];
  }

  public selectQuery() {
    return SQLDatabase.select().from(this.tableName);
  }

  public updateQuery() {
    return SQLDatabase.update().table(this.tableName);
  }

  public deleteQuery() {
    return SQLDatabase.delete().from(this.tableName);
  }

  abstract deserialise(entity: D): Promise<A>;

  public search(
    queryBuilder?: SQLQueryBuilder,
    batch: number = 100
  ): CoreoAsyncIterable<A> {
    const baseQuery = SQLDatabase.select().from(this.tableName);
    const query = queryBuilder ? queryBuilder(baseQuery) : baseQuery;
    const self = this;

    const iterator: CoreoAsyncIterable<A> = {
      [Symbol.asyncIterator](): AsyncIterator<A[]> {
        return this;
      },
      offset: 0,
      done: false,
      async next() {
        if (this.done) {
          return {
            done: true,
            value: [],
          };
        }

        const qs = query.clone().limit(batch).offset(this.offset);

        const result = await SQLDatabase.instance.query<D>(qs);
        const rows = result ?? [];
        const d = await Promise.all(rows.map((row) => self.deserialise(row)));

        if (rows.length === 0) {
          this.done = true;
        } else {
          if (rows.length < batch) {
            this.done = true;
          } else {
            this.offset += batch;
          }
        }

        return {
          done: d.length === 0,
          value: d,
        };
      },

      async toArray() {
        const result = [];
        for await (const i of this) {
          result.push(...i);
        }
        return result;
      },
    };
    return iterator;
  }
}
