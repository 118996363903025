import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { EventEmitter } from '@stencil/core';
import { Unsubscribe, store } from '@stencil/redux';
import { BBox, Feature, Geometry } from 'geojson';
import debounce from 'lodash.debounce';
import mapboxgl, { AnyLayout, AnyPaint, CirclePaint, GeoJSONSource, Layout, LngLatLike, MapboxGeoJSONFeature, SymbolLayout } from 'mapbox-gl';
import { MapInstanceState, MapLayerState } from '../store/map/map.reducer';
import { getAppBounds, getAppCollection, getAppConfigBingToken, getAppFormsWithGeometry, getAppId, getAppMapLayer, getAppNetworkConnected, getOfflineMapsMap } from '../store/selectors';
import { CoreoDataStyle, CoreoGeometry, CoreoMapFeature, CoreoMapFeatureType, CoreoMapLayer } from '../types';
import AppDatabase from './db/app-db.service';
import { projectFilePath } from './db/filesystem.service';
import { geolocationStartTracking, state as geolocationState, onChange as onGeolocationChange } from './geolocation.service';
import { mapboxMapStyleUrl, matchMapboxStyleId } from './geometry.service';
import { imageToImageProxy } from './image.service';
import { MapboxBoundsLayerControl, MapboxUserLocationControl, emptyStyle, pointToBox, safelyAddLayer, safelyAddSource, safelyMoveLayer, safelyRemoveControl, safelyRemoveFeatureState, safelyRemoveLayer, safelyRemoveSource } from './mapbox.service';
import { OfflineMapsService } from './offline-maps.service';

const arrayEquals = <T = any>(a: Array<T>, b: Array<T>): boolean => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export const getMapImageryUrl = async (projectId: number, source: string) => {

  if (!Capacitor.isNativePlatform()) {
    return imageToImageProxy(source);
  }

  const url = new URL(source);
  const filename = url.pathname.split('/').pop();
  const path = projectFilePath(projectId, 'maps', filename);
  const result = await Filesystem.getUri({
    directory: Directory.Data,
    path
  });
  return Capacitor.convertFileSrc(result.uri);
}

const parseCoreoFeature = (feature: MapboxGeoJSONFeature): CoreoMapFeature => {
  let type: string;
  let sourceId: number;

  if (feature.properties.hasOwnProperty('point_count')) {
    type = 'cluster';
  } else if (feature.properties.collectionId) {
    type = 'item';
    sourceId = feature.properties.collectionId;
  } else if (feature.properties.surveyId) {
    type = 'record';
    sourceId = feature.properties.surveyId;
  }

  return {
    type: type as CoreoMapFeatureType,
    sourceId,
    source: feature.source,
    geometry: feature.geometry as CoreoGeometry,
    properties: feature.properties,
    layerId: feature.layer?.id,
    id: feature.id as number
  };
}


export const createDefaultDataLayerStyle = (color: string = '#000000'): CoreoDataStyle => ({
  color,
  contrastColor: null,
  pointRadius: 6,
  pointOpacity: 1,
  pointBorderColor: '#ffffff',
  pointBorderWidth: 1,
  pointBorderOpacity: 1,
  polygonOpacity: 0.3,
  polygonBorderWidth: 1,
  polygonBorderColor: '#ffffff',
  polygonBorderOpacity: 1,
  lineWidth: 1
});
const defaultDataLayerStyle = createDefaultDataLayerStyle();


export abstract class MapLayer {

  public id: string;
  protected map: mapboxgl.Map;

  abstract addTo(map: mapboxgl.Map): Promise<void>;
  abstract layerIds(): string[];

  constructor() {
    this.id = `coreo-${layerId++}`;
  }

  show(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'visible');
      }
    }
  }

  hide(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'none');
      }
    }
  }

  move(): void {
    for (const l of this.layerIds()) {
      safelyMoveLayer(this.map, l);
    }
  }

  remove() {
    for (const l of this.layerIds()) {
      safelyRemoveLayer(this.map, l);
    }
    safelyRemoveSource(this.map, this.id);
    this.map = undefined;
  }
}

const featureStateProperty = (baseValue: any, selectedValue: any, deselectedValue: any, hoverValue: any): mapboxgl.Expression => {
  return [
    'case',
    ['boolean', ['feature-state', 'selected'], false],
    selectedValue,
    ['boolean', ['feature-state', 'deselected'], false],
    deselectedValue,
    ['boolean', ['feature-state', 'hover'], false],
    hoverValue,
    baseValue
  ]
};

const pointPaint = (style: CoreoDataStyle): mapboxgl.CirclePaint => {
  return {
    'circle-color': style.color,
    'circle-opacity': featureStateProperty(
      style.pointOpacity,
      Math.min(style.pointOpacity + 0.1, 1.0),
      Math.max(style.pointOpacity - 0.1, 0.0),
      Math.min(style.pointOpacity + 0.2, 1.0)
    ),
    'circle-radius': featureStateProperty(style.pointRadius, style.pointRadius + 5, style.pointRadius, style.pointRadius + 10),
    'circle-stroke-color': style.pointBorderColor,
    'circle-stroke-width': featureStateProperty(style.pointBorderWidth, style.pointBorderWidth + 2, style.pointBorderWidth, style.pointBorderWidth + 3),
    'circle-stroke-opacity': style.pointBorderOpacity
  };
};
const defaultPointPaint = pointPaint(defaultDataLayerStyle);

const linePaint = (style: CoreoDataStyle): mapboxgl.LinePaint => {
  return {
    'line-color': style.color,
    'line-width': featureStateProperty(
      style.lineWidth,
      style.lineWidth + 2,
      style.lineWidth,
      style.lineWidth + 4
    )
  };
};
const defaultLinePaint = linePaint(defaultDataLayerStyle);

const polygonFillPaint = (style: CoreoDataStyle): mapboxgl.FillPaint => {
  return {
    'fill-color': style.color,
    'fill-opacity': featureStateProperty(
      style.polygonOpacity,
      Math.min(style.polygonOpacity + 0.1, 1),
      Math.max(style.polygonOpacity - 0.1, 0.0),
      Math.min(style.polygonOpacity + 0.2, 1)
    )
  };
}
const defaultPolygonFillPaint = polygonFillPaint(defaultDataLayerStyle);

const polygonBorderPaint = (style: CoreoDataStyle): mapboxgl.LinePaint => {
  return {
    'line-opacity': style.polygonBorderOpacity,
    'line-width': featureStateProperty(
      style.polygonBorderWidth,
      style.polygonBorderWidth + 2,
      style.polygonBorderWidth,
      style.polygonBorderWidth + 4
    ),
    'line-color': style.polygonBorderColor
  };
};
const defaultPolygonBorderPaint = polygonBorderPaint(defaultDataLayerStyle);

let layerId = 0;

interface MapDataLayerOptions {
  minzoom: number;
  maxzoom: number;
  styleKey: string;
  visible: boolean;
  cluster: boolean;
  clusterMaxZoom: number;
  clusterRadius: number;
}

export class MapDataLayer extends MapLayer {
  private styles: (CoreoDataStyle & { id: number; sort: number })[] = [];
  private visible: boolean;
  private styleKey: string;
  private minzoom: number;
  private maxzoom: number;
  private cluster: boolean;
  private clusterMaxZoom: number;
  private clusterRadius: number;
  private source: mapboxgl.GeoJSONSource;
  private selectedFeatureId: number = null;

  constructor(private features: Feature<Geometry>[], options?: Partial<MapDataLayerOptions>) {
    super();

    this.styleKey = options?.styleKey ?? 'surveyId';
    this.minzoom = options?.minzoom ?? 0;
    this.maxzoom = options?.maxzoom ?? 24;
    this.visible = options?.visible ?? true;
    this.cluster = options?.cluster ?? false;
    this.clusterMaxZoom = options?.clusterMaxZoom ?? 12;
    this.clusterRadius = options?.clusterRadius ?? 50;
  }

  add(id: number, sort: number, style: CoreoDataStyle) {
    const idx = this.styles.findIndex(s => s.id === id);
    if (idx === -1) {
      this.styles.push({
        ...defaultDataLayerStyle,
        ...style,
        sort,
        id
      });
    } else {
      this.styles[idx] = {
        ...this.styles[idx],
        sort,
        ...style
      };
    }
  }

  setSort(id: number, sort: number) {
    const idx = this.styles.findIndex(s => s.id === id);
    if (idx === -1) {
      return;
    }
    this.styles[idx] = {
      ...this.styles[idx],
      sort
    };
  }

  clear() {
    this.styles = [];
  }

  clusterClickHandler = (ev) => {
    const [cluster] = ev.features;
    this.source.getClusterExpansionZoom(cluster.properties.cluster_id, (err, zoom) => {
      if (err) {
        console.warn(err);
      } else {
        this.map.easeTo({
          center: cluster.geometry.coordinates as LngLatLike,
          zoom
        });
      }
    });
  };

  private setupMapEvents() {
    this.map.off('click', `${this.id}-clusters`, this.clusterClickHandler);
    this.map.on('click', `${this.id}-clusters`, this.clusterClickHandler);
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    safelyAddSource(map, this.id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.features
      },
      cluster: this.cluster,
      clusterMaxZoom: this.clusterMaxZoom,
      clusterRadius: this.clusterRadius,
      buffer: this.cluster ? 0 : 128,
      maxzoom: 24
    });

    this.source = map.getSource(this.id) as GeoJSONSource;

    this.map = map;
    this.createClusterLayer();
    this.createPolygonLayer();
    this.createLineLayer();
    this.createPointLayer();

    this.setupMapEvents();
  }

  show(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'visible');
      }
    }
    this.visible = true;
  }

  hide(): void {
    for (const l of this.layerIds()) {
      if (typeof this.map?.getLayer(l) !== 'undefined') {
        this.map.setLayoutProperty(l, 'visibility', 'none');
      }
    }
    this.visible = false;
  }

  setFeatures(features: Feature<Geometry>[]) {
    this.features = features;
    this.source?.setData({
      type: 'FeatureCollection',
      features
    });
  }

  selectFeature(id: number) {
    if (this.selectedFeatureId) {
      safelyRemoveFeatureState(this.map, {
        source: this.id,
        id: this.selectedFeatureId
      }, 'selected');
    }
    this.selectedFeatureId = id;
    this.map.setFeatureState({
      source: this.id,
      id
    }, {
      selected: true
    });
  }

  deselectFeature() {
    if (this.selectedFeatureId) {
      safelyRemoveFeatureState(this.map, {
        source: this.id,
        id: this.selectedFeatureId
      }, 'selected');
    }
    this.selectedFeatureId = null;
  }

  setZoomRange(minZoom: number, maxZoom: number) {
    for (const l of this.layerIds()) {
      this.map.setLayerZoomRange(l, minZoom, maxZoom);
    }
  }

  layerIds(): string[] {
    return [
      `${this.id}-polygon`,
      `${this.id}-polygon-border`,
      `${this.id}-linestring`,
      `${this.id}-point`,
      `${this.id}-clusters`,
      `${this.id}-clusters-counts`,
    ];
  }

  private createSortKeyExpression(): mapboxgl.Expression {
    const sort: mapboxgl.Expression = ['match', ['get', this.styleKey]];
    for (const style of this.styles) {
      sort.push(~~style.id);
      sort.push(style.sort);
    }
    sort.push(0);
    return sort;
  }

  private createLayerLayout(sortKey: string): Layout {
    if (this.styles.length <= 1) {
      return {
        'visibility': this.visible ? 'visible' : 'none'

      };
    }
    return {
      'visibility': this.visible ? 'visible' : 'none',
      [sortKey]: this.createSortKeyExpression()
    };
  }

  private createPaint<T extends AnyPaint>(fn: (s: CoreoDataStyle) => T, def: T): T {
    if (this.styles.length === 0) {
      return def;
    }

    if (this.styles.length === 1) {
      return fn(this.styles[0]);
    }

    const [first, ...styles] = this.styles;
    const result: any = {};

    // Build the initial state
    const firstPaint = fn(first);
    for (const p in firstPaint) {
      result[p] = ['match', ['get', this.styleKey], first.id, firstPaint[p]];
    }
    for (const style of styles) {
      const paint = fn(style);
      for (const p in paint) {
        result[p].push(style.id);
        result[p].push(paint[p]);
      }
    }

    for (const p in def) {
      result[p].push(def[p]);
    }

    return result as T;
  }

  private createPointPaint(): mapboxgl.CirclePaint {
    return this.createPaint<mapboxgl.CirclePaint>(pointPaint, defaultPointPaint);
  }

  private createLinePaint(): mapboxgl.LinePaint {
    return this.createPaint<mapboxgl.LinePaint>(linePaint, defaultLinePaint);
  }

  private createPolygonFillPaint(): mapboxgl.FillPaint {
    return this.createPaint<mapboxgl.FillPaint>(polygonFillPaint, defaultPolygonFillPaint);
  }

  private createPolygonBorderPaint(): mapboxgl.LinePaint {
    return this.createPaint<mapboxgl.LinePaint>(polygonBorderPaint, defaultPolygonBorderPaint);
  }


  private createPointLayer() {
    safelyAddLayer(this.map, {
      id: `${this.id}-point`,
      source: this.id,
      type: 'circle',
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      filter: ["all",
        ["==", ["geometry-type"], "Point"],
        ["!", ["has", "point_count"]]
      ],
      layout: this.createLayerLayout('circle-sort-key'),
      paint: this.createPointPaint()
    });
  }

  private createLineLayer() {

    safelyAddLayer(this.map, {
      id: `${this.id}-linestring`,
      source: this.id,
      type: 'line',
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      filter: ["all",
        ["==", ["geometry-type"], "LineString"],
        ["!", ["has", "point_count"]]
      ],
      paint: this.createLinePaint(),
      layout: this.createLayerLayout('line-sort-key'),
    });
  }

  private createClusterCirclePaint(): CirclePaint {
    return {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['get', 'point_count'],
        0, 16,
        this.features.length || 1, 40
      ],
      'circle-stroke-width': 3,
      'circle-color': '#fff',
      'circle-stroke-color': this.styles.length === 1 ? this.styles[0].color : '#0069DF',
    };
  }

  private createClusterCountLayout(): SymbolLayout {
    return {
      'text-field': '{point_count_abbreviated}',
      // 'text-size': 16
      'text-size': [
        'interpolate',
        ['linear'],
        ['get', 'point_count'],
        0, 14,
        this.features.length || 1, 22
      ]
    };
  }

  private createClusterLayer() {

    safelyAddLayer(this.map, {
      id: `${this.id}-clusters`,
      type: 'circle',
      source: `${this.id}`,
      filter: ['has', 'point_count'],
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      paint: this.createClusterCirclePaint()
    });

    safelyAddLayer(this.map, {
      id: `${this.id}-clusters-counts`,
      type: 'symbol',
      source: this.id,
      filter: ['has', 'point_count'],
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      layout: this.createClusterCountLayout(),
      paint: {
        'text-color': '#000'
      }
    });
  }

  private createPolygonLayer() {

    safelyAddLayer(this.map, {
      id: `${this.id}-polygon`,
      source: this.id,
      type: 'fill',
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      filter: ["all",
        ["==", ["geometry-type"], "Polygon"],
        ["!", ["has", "point_count"]]
      ],
      paint: this.createPolygonFillPaint(),
      layout: this.createLayerLayout('fill-sort-key')
    });

    // // Polygon Border
    safelyAddLayer(this.map, {
      id: `${this.id}-polygon-border`,
      source: this.id,
      type: 'line',
      maxzoom: this.maxzoom,
      minzoom: this.minzoom,
      filter: ["all",
        ["==", ["geometry-type"], "Polygon"],
        ["!", ["has", "point_count"]]
      ],
      paint: this.createPolygonBorderPaint(),
      layout: this.createLayerLayout('line-sort-key'),
    });
  }

  updatePaint(layerId: string, paint: AnyPaint) {
    for (const [k, v] of Object.entries(paint)) {
      this.map.setPaintProperty(layerId, k, v);
    }
  }

  updateLayout(layerId: string, layout: AnyLayout) {
    for (const [k, v] of Object.entries(layout)) {
      this.map.setLayoutProperty(layerId, k, v);
    }
  }

  update() {
    if (!this.map) {
      return;
    }

    this.updatePaint(`${this.id}-point`, this.createPointPaint());
    this.updatePaint(`${this.id}-linestring`, this.createLinePaint());
    this.updatePaint(`${this.id}-polygon`, this.createPolygonFillPaint());
    this.updatePaint(`${this.id}-polygon-border`, this.createPolygonBorderPaint());
    this.updatePaint(`${this.id}-clusters`, this.createClusterCirclePaint());

    this.updateLayout(`${this.id}-point`, this.createLayerLayout('circle-sort-key'));
    this.updateLayout(`${this.id}-linestring`, this.createLayerLayout('line-sort-key'));
    this.updateLayout(`${this.id}-polygon`, this.createLayerLayout('fill-sort-key'));
    this.updateLayout(`${this.id}-polygon-border`, this.createLayerLayout('line-sort-key'));
    this.updateLayout(`${this.id}-clusters-counts`, this.createClusterCountLayout());
  }

  remove(): void {
    // Remove event bindings
    this.map?.off('click', `${this.id}-clusters`, this.clusterClickHandler);
    this.source = undefined;

    // Call parent to remove layers
    super.remove();
  }
}

class MapRasterLayer extends MapLayer {

  constructor(private url: string, private coordinates: any, private projectId: number) {
    super();
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    this.map = map;
    const url = await getMapImageryUrl(this.projectId, this.url);


    safelyAddSource(this.map, this.id, {
      type: 'image',
      url,
      coordinates: this.coordinates
    });

    safelyAddLayer(this.map, {
      id: this.id,
      'type': 'raster',
      'source': this.id,
      'paint': {
        'raster-fade-duration': 0
      }
    });
  }

  remove(): void {
    safelyRemoveLayer(this.map, this.id);
    safelyRemoveSource(this.map, this.id);
    this.map = undefined;
  }

  layerIds(): string[] {
    return [this.id];
  }
}

class MapCustomLayer extends MapLayer {
  constructor(private features: Feature<Geometry>[], private config: CoreoMapLayer) {
    super();
  }

  async addTo(map: mapboxgl.Map): Promise<void> {
    this.map = map;

    safelyAddSource(map, this.id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.features
      },
      promoteId: 'id'
    });

    const layer: any = {
      id: this.id,
      type: this.config.type ?? 'heatmap',
      source: this.id,
      layout: this.config.layout ?? {},
      paint: this.config.paint ?? {},
    };

    if (this.config.filter) {
      layer.filter = this.config.filter;
    }

    safelyAddLayer(map, layer);
  }

  layerIds(): string[] {
    return [this.id];
  }
}

const getRecordsFeatures = (surveyId: number, projectId: number) => AppDatabase.instance.records.findProjectMapFeatures(q => q.where('surveyId = ?', surveyId).where('projectId = ?', projectId));
const getCollectionFeatures = (collectionId: number, projectId: number) => AppDatabase.instance.items.findProjectMapFeatures(q => q.where('collectionId = ?', collectionId).where('projectId = ?', projectId));

const createMapLayer = async (layerState: MapLayerState): Promise<MapLayer> => {

  const state = store.getState();
  const projectId = getAppId(state);

  if (layerState.layerType === 'collection') {
    const collection = getAppCollection(layerState.sourceId)(state);
    const features = await getCollectionFeatures(layerState.sourceId, projectId);
    const mapLayer = new MapDataLayer(features);
    mapLayer.add(collection.id, collection.mapSort, collection.style);
    return mapLayer;
  } else if (layerState.layerType === 'custom') {
    const layerConfig: CoreoMapLayer = getAppMapLayer(layerState.sourceId)(state);
    if (layerConfig.sourceType === 'image') {
      const mapLayer = new MapRasterLayer(layerConfig.source, layerConfig.layout, getAppId(state));
      return mapLayer;
    }
    const features = await (layerConfig.sourceType === 'records' ? getRecordsFeatures(layerConfig.sourceId, projectId) : getCollectionFeatures(layerConfig.sourceId, projectId));
    const mapLayer = new MapCustomLayer(features, layerConfig);
    return mapLayer;
  }
}

const refreshMapLayer = async (layerState: MapLayerState, layer: MapLayer): Promise<void> => {
  const state = store.getState();
  switch (layerState.layerType) {
    case 'collection': {
      const collection = getAppCollection(layerState.sourceId)(state);
      const features = await getCollectionFeatures(layerState.sourceId, getAppId(state));
      (layer as MapDataLayer).setFeatures(features);
      (layer as MapDataLayer).add(collection.id, collection.mapSort, collection.style);
      (layer as MapDataLayer).update();
      break;
    }
    case 'custom': {
      break;
    }
    default: {
      console.log('dont know how to refresh this');
    }
  }
}

export const mapInstanceDataLayersUpdated = (a: MapInstanceState, b: MapInstanceState): boolean => {
  const existingDataLayers = a.dataLayers.filter(l => l.enabled).map(l => l.sourceId);
  const currentDataLayers = b.dataLayers.filter(l => l.enabled).map(l => l.sourceId);
  return JSON.stringify(existingDataLayers) !== JSON.stringify(currentDataLayers);
}

export type MapFeatureFilter = () => (feature: Feature) => boolean;

export interface MapInterface {
  el: HTMLElement;
  previous: MapInterface;
  container: HTMLDivElement;

  bounds: any;
  boundsMaxZoom?: number;
  boundsPadding?: number;

  cluster: boolean;

  attributionPosition?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

  tracking: boolean;
  showRecenter: boolean;

  state: MapInstanceState;

  mapReady: EventEmitter<mapboxgl.Map>;
  mapReleased: EventEmitter<void>;
  mapStyleLoad: EventEmitter<mapboxgl.Map>;
  featureClicked: EventEmitter<CoreoMapFeature[]>;
  mapClicked: EventEmitter<void>;
  featureFocused: EventEmitter<any>;
  mapStateChanged: EventEmitter<MapInstanceState>;
  mapStateUpdated: EventEmitter<MapInstanceState>;

  featureFilter?: MapFeatureFilter;

  background?: boolean;
  layers?: MapLayer[];
}

interface MapInformation {
  bounds: mapboxgl.LngLatBounds;
  center: mapboxgl.LngLat;
  bearing: number;
  pitch: number;
  zoom: number;
}

class MapService {

  private constructor() { }

  public static instance: MapService = new MapService();

  private map: mapboxgl.Map;
  private mapContainer: HTMLDivElement;
  private previousState: MapInstanceState;

  private activeMap: MapInterface;
  private mapInfo: WeakMap<MapInterface, MapInformation> = new WeakMap();

  private intervalHandle: number;
  private networkSubscription: Unsubscribe;

  private locationHandle: MapboxUserLocationControl;
  private emptyHandle: number;
  private scaleControl: mapboxgl.ScaleControl;

  private features: Feature[] = [];
  private featuresLayer: MapDataLayer = new MapDataLayer([]);
  private clustersLayer: MapDataLayer = new MapDataLayer([], {
    cluster: true
  });
  private mapLayers: Map<string, { layer: MapLayer; state: MapLayerState }> = new Map();
  // private currentBounds: MultiPolygon;
  private appBoundsLayer: MapboxBoundsLayerControl;
  private networkConnected: boolean = false;

  public init() {
    AppDatabase.instance.events.addListener('syncDidUpdate', debounce(async (projectId: number, _records: boolean, config: boolean) => {
      if (this.activeMap && getAppId(store.getState()) === projectId) {
        await this.loadFeatures(projectId);
      }

      if (config) {
        this.refreshLayers();
      }

    }, 300, { trailing: true }));
  }

  private activeMapStateListener = async () => {
    await this.applyMapState(this.activeMap);
    this.activeMap.mapStateUpdated.emit(this.activeMap.state);
  };

  private onMapInitialLoad = () => {

    // Check to see if we are initializeing to an offline map
    const offlineMap = getOfflineMapsMap(this.activeMap.state.baseStyleId)(store.getState());
    if (typeof offlineMap !== 'undefined') {
      this.setMapStyle(this.activeMap.state.baseStyleId);
      return;
    }

    // Try to upgrade every 5 seconds
    this.intervalHandle = window.setInterval(this.upgradeMap, 5000);

    // Also try to upgrade as soon as the network becomes online
    this.networkConnected = getAppNetworkConnected(store.getState());
    this.networkSubscription = store.getStore().subscribe(() => {
      this.networkConnected = getAppNetworkConnected(store.getState())
      if (this.networkConnected) {
        this.upgradeMap();
      }
    });

    // Try to upgrade immediately
    if (this.networkConnected) {
      this.upgradeMap();
    }
  };

  private onTouchmove = () => {
    if (this.activeMap) {
      this.activeMap.tracking = false;
    }
  }

  private onRotateEnd = () => {
    if (this.activeMap) {
      this.activeMap.showRecenter = this.map.getBearing() !== 0;
    }
  }

  private onMapLoad = () => {
    this.map.on('touchmove', this.onTouchmove);
    this.map.on('rotateend', this.onRotateEnd);
    this.locationHandle = new MapboxUserLocationControl(geolocationState.location);
    this.map.addControl(this.locationHandle);
  };

  private updateClustering(cluster: boolean) {
    if (cluster) {
      this.featuresLayer.setZoomRange(12, 24);
      this.clustersLayer.setZoomRange(0, 12);
      this.clustersLayer.show();
    } else {
      this.featuresLayer.setZoomRange(0, 24);
      this.clustersLayer.hide();
    }
  }

  private onMapStyleLoad = async () => {
    let currentStyle: mapboxgl.Style;
    try {
      currentStyle = this.map?.getStyle();
    } catch (e) {
      currentStyle = null;
    }

    if (currentStyle?.name === 'empty') {
      // If we don't yet have an empty handle, this is the first time
      if (typeof this.emptyHandle === 'undefined') {
        this.emptyHandle = window.setTimeout(this.onMapStyleLoad, 3000);
        return;
      }
    }

    if (this.emptyHandle) {
      window.clearTimeout(this.emptyHandle);
      this.emptyHandle = undefined;
    }

    // Attach the features layers
    await Promise.all([
      this.featuresLayer.addTo(this.map),
      this.clustersLayer.addTo(this.map)
    ]);

    // Update clustering
    this.updateClustering(this.activeMap.cluster);

    // Render the maps state
    await this.applyMapState(this.activeMap);
    this.activeMap?.mapStyleLoad.emit(this.map);
  };

  private onMapClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const clickedFeatures: any[] = this.map.queryRenderedFeatures(pointToBox(e.point, 15), {
      validate: false
    });

    if (clickedFeatures.some(s => s.layer?.id?.startsWith('MAP_GEOMETRY_SELECTOR'))) {
      return;
    }

    const features = clickedFeatures.filter(f => {
      return f.source.startsWith('coreo');
    })
      // Remove duplicates
      .filter((value, index, arr) => index === arr.findIndex(t => t.id === value.id))
      .map(f => parseCoreoFeature(f));

    if (features.length === 0) {
      this.fireMapClickEvent(e);
    }

    this.map.fire('coreo.featureClicked', {
      features
    });
    this.activeMap?.featureClicked.emit(features);
  };

  public fireMapClickEvent(e?: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
    this.map?.fire('coreo.mapClicked', { originalEvent: e });
    this.activeMap?.mapClicked.emit();
  }

  private async resolveMapStyle(styleId: string): Promise<mapboxgl.Style | string> {
    // Check bing
    if (styleId === 'bing') {
      return this.loadBingMapStyle();
    }

    // Check mapbox styles
    const mapboxStyle = matchMapboxStyleId(styleId);
    if (mapboxStyle) {
      return mapboxMapStyleUrl(mapboxStyle);
    }

    // Check offline maps
    const offlineMap = getOfflineMapsMap(styleId)(store.getState());
    if (offlineMap) {
      return OfflineMapsService.instance.getOfflineMap(offlineMap);
    }

    return emptyStyle;
  }

  private async loadBingMapStyle(): Promise<mapboxgl.Style> {
    const imagerySet = 'Aerial';
    const token = getAppConfigBingToken(store.getState());
    const culture = 'en-GB';
    const d = await fetch(`https://dev.virtualearth.net/REST/V1/Imagery/Metadata/${imagerySet}?output=json&uriScheme=https&include=ImageryProviders&key=${token}`);
    const data = await d.json();
    const resourceSets = data.resourceSets[0];
    const resources = resourceSets.resources;
    const resource = resources[0];

    const imageUrl: string = resource.imageUrl;
    const imageUrlSubdomains: string[] = resource.imageUrlSubdomains;

    const tiles = imageUrlSubdomains.map(subdomain => {
      return imageUrl.replace('{subdomain}', subdomain)
        .replace('{culture}', culture);
    });

    const minzoom = resource.zoomMin;
    // 20 seems to be the practical max zoom level, so don't go below that
    const maxzoom = Math.min(resource.zoomMax, 20);

    return {
      version: 8,
      glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
      name: 'Bing Aerial',
      sources: {
        'bing': {
          type: 'raster',
          tiles: tiles,
          tileSize: 256,
          minzoom,
          maxzoom
        }
      },
      layers: [{
        id: 'bing',
        type: 'raster',
        source: 'bing'
      }]
    };
  }

  private async setMapStyle(styleId: string) {
    const style = await this.resolveMapStyle(styleId);
    this.map.setStyle(style, { diff: false });
  }

  private upgradeMap = async () => {
    if (!this.networkConnected) {
      return;
    }

    try {
      const currentStyle = this.map?.getStyle();
      if (!currentStyle) {
        return;
      }

      if (currentStyle.name === 'empty') {
        this.setMapStyle(this.activeMap.state.baseStyleId);
      } else {
        this.cancelUpgradeMap();
      }
    } catch (e) {
      console.warn(e);
    }
  }

  private cancelUpgradeMap() {
    if (typeof this.intervalHandle !== 'undefined') {
      window.clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
    if (typeof this.networkSubscription !== 'undefined') {
      this.networkSubscription();
      this.networkSubscription = undefined;
    }
  }

  private initializeMap(request: MapInterface) {
    const { container, bounds, boundsMaxZoom, boundsPadding } = request;

    // Create a container for our map
    this.mapContainer = document.createElement('div');
    this.mapContainer.classList.add('map-master');

    // Append it into our first requester
    container.prepend(this.mapContainer);

    const options: mapboxgl.MapboxOptions = {
      container: this.mapContainer,
      style: emptyStyle,
      bounds,
      fitBoundsOptions: {
        maxZoom: boundsMaxZoom ?? 12,
        padding: boundsPadding ?? 40
      },
      attributionControl: false,
      trackResize: false,
      projection: 'mercator' as any,
      transformRequest: OfflineMapsService.instance.offlineMapTransformRequestFunction,
      fadeDuration: 0
    };
    this.map = new mapboxgl.Map(options);
    this.map.addControl(new mapboxgl.AttributionControl(), request.attributionPosition ?? 'bottom-right');

    // Setup upgrade handles
    this.map.once('load', this.onMapInitialLoad);
    this.map.on('load', this.onMapLoad);
    this.map.on('style.load', this.onMapStyleLoad);

    // Setup generic coreo map feature click handler
    this.map.on('click', this.onMapClick);

    geolocationStartTracking();

    onGeolocationChange('location', location => {
      if (this.locationHandle) {
        this.locationHandle.setPosition(location);
      }
      // if (this.tracking) {
      //   this.centerMap({
      //     center: geolocationState.location as LngLatLike
      //   });
      // }
    });

    onGeolocationChange('heading', heading => {
      if (this.locationHandle) {
        this.locationHandle.setHeading(heading);
      }
    });

    return this.map;
  }

  private storeMapInfo(element: MapInterface) {
    this.mapInfo.set(element, {
      bounds: this.map.getBounds(),
      center: this.map.getCenter(),
      zoom: this.map.getZoom(),
      bearing: this.map.getBearing(),
      pitch: this.map.getPitch()
    });
  }

  public async requestMap(element: MapInterface) {
    // If we have an existing map, store it's information first
    if (this.activeMap) {
      this.storeMapInfo(this.activeMap);
      this.activeMap.el.removeEventListener('mapStateChanged', this.activeMapStateListener);
      this.activeMap.container.classList.add('map-hidden');
      (this.activeMap.layers || []).map(l => l.remove());
      this.activeMap.mapReleased.emit();
    }

    // Set the currently active maps previous pointer to the incoming map
    element.previous = this.activeMap;

    // Set our new active map
    this.activeMap = element;
    this.activeMap.el.addEventListener('mapStateChanged', this.activeMapStateListener, {
      passive: true
    });

    if (!this.map) {
      return this.initializeMap(element);
    }

    // Move the map now it's hidden
    const attachMap = async () => {

      // Hide the container element whilst we move the map into it
      element.container.classList.add('map-hidden');

      // Move the map into the container
      element.container.appendChild(this.mapContainer);

      // Resize the map in its new location
      this.map.resize();

      // Update the center of the map
      const { bounds, boundsPadding, boundsMaxZoom } = element;
      if (bounds) {
        const { center, zoom, bearing } = this.map.cameraForBounds(bounds, {
          maxZoom: boundsMaxZoom,
          padding: boundsPadding
        });

        this.map.jumpTo({
          center,
          zoom,
          bearing
        });
      }

      // Update clustering
      this.updateClustering(element.cluster);

      // Apply the state
      if (await this.applyMapState(element)) {
        element.mapReady.emit(this.map);
        element.mapStyleLoad.emit(this.map);
      }

      // Reveal the map
      setTimeout(() => element.container.classList.remove('map-hidden'), 100);
    };
    requestAnimationFrame(attachMap);
    return this.map;
  }

  private reconnectMap = async () => {
    // Move the map to its new location and resize
    this.activeMap.container.appendChild(this.mapContainer);
    this.map.resize();

    // Center the map back to its previous location
    const info = this.mapInfo.get(this.activeMap);
    const { bounds, bearing, pitch } = info;
    const { center, zoom } = this.map.cameraForBounds(bounds);
    this.map.jumpTo({
      center,
      zoom,
      bearing,
      pitch
    });

    // Attach listener
    this.activeMap.el.addEventListener('mapStateChanged', this.activeMapStateListener, {
      passive: true
    });

    // Apply state
    this.updateClustering(this.activeMap.cluster);
    this.activeMap.showRecenter = bearing !== 0;
    await this.applyMapState(this.activeMap);

    // Re-trigger the map events
    this.activeMap.mapReady.emit(this.map);
    this.activeMap.mapStyleLoad.emit(this.map);

    // Finally remove the hidden
    setTimeout(() => this.activeMap.container.classList.remove('map-hidden'), 100);
  }

  public async releaseMap(element: MapInterface) {
    this.activeMap = element.previous;
    element.el.removeEventListener('mapStateChanged', this.activeMapStateListener);
    element.previous = undefined;
    (element.layers || []).map(layer => layer.remove());
    element.mapReleased.emit();

    // Persist the base style
    if (this.activeMap) {
      this.activeMap.state.baseStyleId = element.state.baseStyleId;
      if (!this.activeMap.background) {
        requestAnimationFrame(this.reconnectMap);
      }
    }
  }

  public async loadFeatures(projectId: number) {
    this.features = await AppDatabase.instance.records.findProjectMapFeatures(query => {
      query.where('projectId = ?', projectId);
      query.field('createdAt');
      query.field('state');
      query.field('userId');
      return query;
    });
    if (this.activeMap) {
      this.filterMapFeatures(this.activeMap.state);
    }
  }

  public getBounds(): BBox {
    return this.map?.getBounds().toArray().flat() as BBox;
  }

  public getMap() {
    return this.map;
  }

  private updateScaleBar(showScaleBar: boolean) {
    // If we have the control and we don't want it, remoe it
    const hasControl = this.map.hasControl(this.scaleControl);
    if (!showScaleBar && hasControl) {
      this.map.removeControl(this.scaleControl);
    } else if (showScaleBar) {
      if (typeof this.scaleControl === 'undefined') {
        this.scaleControl = new mapboxgl.ScaleControl({
          unit: 'metric',
          maxWidth: 240
        });
      }
      if (!hasControl) {
        this.map.addControl(this.scaleControl, 'top-left');
      }
    }
  }

  private updateProjectBounds(showProjectBounds: boolean) {
    const appBounds = getAppBounds(store.getState());
    // If we are showing bounds, and we don't already have one
    if (this.appBoundsLayer) {
      safelyRemoveControl(this.map, this.appBoundsLayer);
    }

    if (showProjectBounds && appBounds) {
      this.appBoundsLayer = new MapboxBoundsLayerControl(appBounds);
      this.map.addControl(this.appBoundsLayer);
    } else if (this.appBoundsLayer && (!showProjectBounds || !appBounds)) {
      this.appBoundsLayer = undefined;
    }
  }

  private async applyMapStateLayers(state: MapInstanceState) {

    const currentIds = state.layers.map(l => l.id);
    for (const [id, layerRef] of this.mapLayers.entries()) {
      const { layer } = layerRef;
      if (!currentIds.includes(id)) {
        layer.remove();
        this.mapLayers.delete(id);
      }
    }

    for (const layerState of state.layers) {
      const existing = this.mapLayers.get(layerState.id);
      if (layerState.enabled) {
        if (typeof existing === 'undefined') {
          const newLayer = await createMapLayer(layerState);
          this.mapLayers.set(layerState.id, {
            layer: newLayer,
            state: layerState
          });
          await newLayer.addTo(this.map);
        } else {
          await existing.layer.addTo(this.map);
          existing.layer.show();
        }
      } else {
        existing?.layer?.hide();
      }
    }
  }

  private clearMapFeatures() {
    this.featuresLayer.clear();
    this.clustersLayer?.clear();
    this.featuresLayer.setFeatures([]);
    this.clustersLayer?.setFeatures([]);
  }

  public triggerFeaturesRefresh(element: MapInterface) {
    if (this.activeMap === element) {
      this.filterMapFeatures(element.state);
    }
  }

  public notifyBackgroundChange(element: MapInterface) {
    if (element === this.activeMap && !element.background && !element.el.contains(this.mapContainer)) {
      this.reconnectMap();
    }
  }

  public filterMapFeatures(state: MapInstanceState) {
    const allDisabled = state.dataLayers.every(a => !a.enabled);
    const formIds = new Set([...state.dataLayers.filter(a => a.enabled).map(d => d.sourceId)]);

    // If there are no forms, and now custom filter function, return now
    if ((allDisabled || (formIds.size === 0)) && typeof this.activeMap.featureFilter === 'undefined') {
      return this.clearMapFeatures();
    }

    let filtered = this.features.filter(f => formIds.has(f.properties.surveyId));
    if (this.activeMap.featureFilter) {
      filtered = filtered.filter(this.activeMap.featureFilter());
    }

    this.featuresLayer.setFeatures(filtered);
    this.clustersLayer.setFeatures(filtered.map(({ properties, ...f }) => ({
      ...f,
      geometry: properties.center,
      properties
    })));
  }

  public getFeatures() {
    return this.features;
  }

  private async applyMapDataLayers(state: MapInstanceState) {

    const geometricForms = getAppFormsWithGeometry(store.getState());

    this.featuresLayer.clear();
    this.clustersLayer.clear();

    if (geometricForms.length > 0) {
      for (const form of geometricForms) {
        this.featuresLayer.add(form.id, form.mapSort, form.style);
        this.clustersLayer.add(form.id, form.mapSort, form.style);
      }

      for (let i = 0; i < state.dataLayers.length; i++) {
        this.featuresLayer.setSort(state.dataLayers[i].sourceId, i + 1);
        this.clustersLayer.setSort(state.dataLayers[i].sourceId, i + 1);
      }

      this.featuresLayer.update();
      this.clustersLayer?.update();

      // If we have change data layers, re-filter the data
      if (this.previousState) {
        const current = state.dataLayers.filter(a => a.enabled).map(a => a.sourceId).sort();
        const previous = this.previousState?.dataLayers.filter(a => a.enabled).map(a => a.sourceId).sort();

        if (!arrayEquals(current, previous)) {
          this.filterMapFeatures(state);
        }
      }

    } else {
      this.clearMapFeatures();
    }
  }

  public async applyMapState(element: MapInterface): Promise<boolean> {

    const { state } = element;

    // First check if the base style has changed
    if (this.previousState && this.previousState.baseStyleId !== state.baseStyleId) {
      this.setMapStyle(state.baseStyleId);
      this.previousState = state;

      // If we are changing the entire style, this process will resume
      // once the style has loaded. So return now
      return false;
    }

    // Handle Scale Bar
    this.updateScaleBar(state.showScaleBar);

    // Handle Project Bounds
    this.updateProjectBounds(state.showAppBounds);

    // Render base layers
    await this.applyMapStateLayers(state);

    // Render data layers
    this.applyMapDataLayers(state);

    // Move our feature and clusters layer to the top
    this.clustersLayer.move();
    this.featuresLayer.move();

    (element.layers || []).map(l => l.addTo(this.map));

    this.previousState = state;
    return true;
  }

  public async refreshLayers() {
    for (const [_id, layerRef] of this.mapLayers.entries()) {
      const { layer, state } = layerRef;
      refreshMapLayer(state, layer);
    }
  }

}

export default MapService;
